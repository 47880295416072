body {
    background: linear-gradient(32deg, #4528c3, #f9b52e);
    min-height: 100vh;
}

td.fame {
    max-width: 150px;
    word-wrap: break-word;
}
td.fame-div {
    border-right: solid 1px #7a7a7a;
}

.well {
    filter: drop-shadow(3px 3px 4px #cccccc);
    background: white;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
}

.well-sm {
    filter: drop-shadow(2px 2px 3px #cccccc);
    background: white;
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;
}

.bg-fancy {
    background: transparent !important;
    /*border-width: 50px 40px !important;
    border-image: url(/assets/img/login-bg.webp) 80 90 fill !important;*/
    border-width: 40px 30px !important;
    border-image: url(/public/assets/img/login-bg.webp) 80 90 fill !important;
    color: white;
    padding-top: 16px;
    padding-bottom: 16px;
}

.article-iframe {
    margin: -70px;
    min-width: 450px;
    background-color: white;
}

.store-link {
    max-width: 170px;
    max-height: 65px;
}

@media screen and (min-width: 576px) {
    .bg-fancy {
        border-width: 25px 45px !important;
        border-image: url(/public/assets/img/login-bg.webp) 80 150 fill !important;
    }
}
@media screen and (min-width: 768px) {
    .bg-fancy {
        border-width: 50px 90px !important;
        border-image: url(/public/assets/img/login-bg.webp) 80 150 fill !important;
    }

    .article-iframe {
        margin: auto;
    }
}

.bg-fancy-offset {
    margin-top: -12px;
}

.header-space {
    height: 65px;
}

.bg-simple {
    /*background-image: url(/public/assets/img/button-bg-simple.png);
    background-size: 100% 100%;
    height: 100%;
    padding: 8px 16px;*/

    border: solid;
    border-image: url(/public/assets/img/button-bg-simple.png) 12 16 fill;
    border-width: 8px 12px;
}

.btn-fancy {
    position: relative;
    color: white;
    font-weight: bold;
    padding: 7px 25px;
    border: none;
}

.btn-fancy-border {
    position: absolute;
    top: -2px;
    left: -15px;
    width: calc(100% + 30px);
    height: calc(100% + 4px);

    border: solid;
    border-width: 12px 25px !important;
    border-image: url(/public/assets/img/button-alt-glow.png) 40 100 fill !important;
}

.btn-fancy:hover:not(:disabled) > .btn-fancy-border {
    filter: saturate(130%) brightness(120%);
}

.btn-fancy:disabled {
    filter: saturate(0%) brightness(110%);
}

.bg-shade {
    background-image: url(/public/assets/img/button-dark.png);
    background-size: 100% 100%;
    border: none;
}

.bg-green {
    background-color: #247637;
}

.bg-blue {
    background-color: #2C738E;
}

.bg-purple {
    background-color: #48164B;
}

.bg-red {
    background-color: #762426;
}

.btn-fancy .btn-fancy-img {
    position: absolute;
    left: 0;
    /*width: calc(100% + 60px);*/
    width: 100%;

    top: 0;
    /*height: calc(100% + 24px);*/
    height: 100%;
}

.btn-fancy-img-border {
    /*margin: -5% -20%;*/

    top: -12.5%;
    left: -20%;
    height: 125%;
    width: 140%;
}

#title-text {
    margin-left: -100%;
    background: linear-gradient(77deg, #7d23f3, #f9ae25);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: saturate(1.75) brightness(1.75);
}

/* The animation code */
@keyframes wingLeft {
    0% {transform: scale(-1,1) rotateY(-15deg) scale(102%);}
    50% {transform: scale(-1,1) rotateY(15deg) scale(98%);}
    100% {transform: scale(-1,1) rotateY(-15deg) scale(102%);}
}
@keyframes wingRight {
    0% {transform: rotateY(15deg) scale(102%) perspective(500px);}
    50% {transform: rotateY(-15deg) scale(98%);}
    100% {transform: rotateY(15deg) scale(102%);}
}

/* The element to apply the animation to */
#wingLeft {
    transform-origin: center left;
    animation-name: wingLeft;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
#wingRight {
    transform-origin: center left;
    animation-name: wingRight;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes filterPulsate {
    0% {filter: saturate(1) brightness(1);}
    50% {filter: saturate(1.3) brightness(1.7);}
    100% {filter: saturate(1) brightness(1);}
}

.pulsate {
    animation-name: filterPulsate;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.filter-saturate {
    filter: saturate(1.15) brightness(1.3);
}

.playerName {
    border: solid;
    border-image: url(/public/assets/img/player-banner-filled.png) 15 40 fill;
    border-width: 10px 20px;
    padding: 2px 6px;
    font-size: 120%;
    font-weight: bold;
}

.filter-gray {
    filter: saturate(0);
}

.resource-icon {
    filter: saturate(1.1) brightness(1.1);
    max-width: 15vw;
}

a, .link-style {
    filter: drop-shadow(1px 1px 3px #600ca2);
    text-decoration: none;
    color: white !important;
}

a:hover,
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    filter: saturate(1.2) brightness(1.2) drop-shadow(1px 1px 5px #bf66f3);
}

.scroll-target {
    position: absolute;
    top: -140px;
}

.fs-7 {
    font-size: 0.75rem!important
}

@keyframes scaleUpAndDown {
    0%, 100% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}

.particle {
    position: absolute;
    border-radius: 50%;
    animation-fill-mode: forwards;
}

.rect-particle {
    animation: scaleUpAndDown 4s linear;
    animation-iteration-count: infinite;
}

.char-top {
    margin-left: auto;
    margin-right: auto;

    margin-top: -80px;
    width: 128px;
}
.char-topright {
    position: absolute;
    width: 180px;
    max-width: 50vw;

    margin-top: -80px;
    right: 0;
    top: -32px;
}
